import { useAppStore } from "../../stores/app-store";
import { AsSeenIn } from "./as-seen-in/AsSeenIn";
import { Awards } from "./awards/Awards";
import BigNumberBanners from "./big-numbers-banners/components/BigNumberBanners";
import BusinessFooter from "./business-footer/BusinessFooter";
import { BusinessPageIntro } from "./business-page-intro/BusinessPageIntro";
import LiveDemosButton from "./demo-page-button/DemoPageButton";
import GotQuestions from "./got-questions/GotQuestions";
import WeAreGlobal from "./we-are-global/WeAreGlobal";
import { WhatClientsSay } from "./what-clients-say/WhatClientsSay";
import { WhatWeDo } from "./what-we-do/WhatWeDo";
import { WeWork } from "./who-we-work-with/WeWork";

export function BusinessPage() {
  const lang = useAppStore((state) => state.language);

  return (
    <div className="business-page">
      <BusinessPageIntro />
      <LiveDemosButton lang={lang} />
      <WeWork />
      <BigNumberBanners />
      <WeAreGlobal />
      <WhatWeDo />
      <AsSeenIn />
      <WhatClientsSay />
      <Awards />
      <GotQuestions />
      <BusinessFooter />
    </div>
  );
}
